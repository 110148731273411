import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "state-machines"
      }}>{`State Machines`}</h1>
      <p>{`As it turns out, this super old concept in Computer Science is fantastic for managing UI state.`}</p>
      <p>{`Our libarary of choice is `}<a parentName="p" {...{
          "href": "https://xstate.js.org/"
        }}>{`xstate`}</a>{` for a few reasons:`}</p>
      <ul>
        <li parentName="ul">{`No dependencies`}</li>
        <li parentName="ul">{`Closely follows the `}<a parentName="li" {...{
            "href": "https://www.w3.org/TR/scxml/"
          }}>{`SCXML Specification`}</a></li>
        <li parentName="ul">{`The `}<a parentName="li" {...{
            "href": "https://xstate.js.org/viz/"
          }}>{`visualizer`}</a>{` is fantastic`}</li>
        <li parentName="ul">{`It works great and we haven't had to look for a different option`}</li>
      </ul>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "how-state-machines-works"
      }}>{`How State Machines works`}</h2>
      <p>{`The `}<a parentName="p" {...{
          "href": "https://xstate.js.org/docs/"
        }}>{`XState documentation`}</a>{` great and is the best resource for up-to-date tutorials.`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "why-we-use-state-machines-for-ui-state"
      }}>{`Why we use state machines for UI state`}</h2>
      <p>{`State machines are defined as a set of specific states and the transitions between them. This makes them an optimal choice for managing UI state.`}</p>
      <p>{`Benefits:`}</p>
      <ul>
        <li parentName="ul">{`A documented set of UI states`}</li>
        <li parentName="ul">{`Specific transitions from one state to another`}</li>
        <li parentName="ul">{`Forces us to design with all states in mind as well as how to navigate them`}</li>
        <li parentName="ul">{`No more broken states leaving a customer stranded`}</li>
        <li parentName="ul">{`Emphasis on loading and error states`}</li>
      </ul>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "how-we-use-them"
      }}>{`How we use them`}</h2>
      <p>{`As we've learned how to use state machines in a practical way, it has become clear that multiple smaller machines are favourable to larger ones.`}</p>
      <p>{`Aside from the `}<a parentName="p" {...{
          "href": "https://github.com/rebeldotcom/rebel-web-ui/tree/main/src/features/security-settings/two-factor"
        }}>{`2FA feature`}</a>{`, which is the first feature done using XState, we typically define and use machines within context providers.`}</p>
      <p>{`Current implementations of this pattern include:`}</p>
      <ul>
        <li parentName="ul">{`Authentication (`}<a parentName="li" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-ui/blob/main/src/providers/user/user-provider.js"
          }}>{`user-provider.js`}</a>{`)`}</li>
        <li parentName="ul">{`Payment Settings (`}<a parentName="li" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-ui/blob/main/src/providers/credit-card/credit-card-payment-provider.tsx"
          }}>{`credit-card-payment-provider.tsx`}</a>{`)`}</li>
        <li parentName="ul">{`Google Workspace (`}<a parentName="li" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-ui/blob/main/src/providers/google-workspace/google-workspace-provider.js"
          }}>{`google-workspace-provider.js`}</a>{`)`}</li>
      </ul>
      <Box mt={3} mdxType="Box">
        <p>{`By combining the use of small machines within the same context provider, we can keep the logic more concise. For example, CRUD operations would each have their own machines. The provider stitches these machines together and allows for added logic between machines. This is all absctracted away from any UI components using the context provider.`}</p>
      </Box>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      